import React, { useState, useEffect } from "react";
import data from "../../../data/master/analytics.json";
import Modal from "react-bootstrap/Modal";
import PageLayout from "../../../layouts/PageLayout";
import { ToastContainer, toast } from "react-toastify";
import Badge from "react-bootstrap/Badge";
import {
  Box,
  Icon,
  Text,
  Item,
  Anchor,
  Heading,
} from "../../../components/elements";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { DataGrid } from "@mui/x-data-grid";

import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import {
  deleteCourseAPI,
  getAllCourseAPI,
} from "../../../Services/MasterServices/courseMasterAPIURL";
import { getAllStudentsAPI } from "../../../Services/MasterServices/studentMasterAPIURL";
import {
  getAllEnrollAPI,
  getCertificateDetailsAPI,
  saveEnrollAPI,
  updateEnrollAPI,
  updateEnrollStatusAPI,
} from "../../../Services/MasterServices/enrollmentMasterAPIURL";
import moment from "moment";
import { QRCode } from "react-qrcode-logo";
import qrIcon from "./barcodeicon.png";
import Loader from "../../../layouts/Loader";
import { MdQrCode2 } from "react-icons/md";
import { getAllSoftwareAPI } from "../../../Services/MasterServices/softwareMasterAPIURL";

const EnrollmentMaster = () => {
  const [loaderStatus, setLoaderStatus] = useState(false);

  const [alertModal, setAlertModal] = useState(false);
  const [qRModal, setQRModal] = useState(false);
  const [crUploadModal, setCrUpload] = useState(false);

  const [qrId, setQRid] = useState("");

  const [id, setId] = useState("");
  const [grade, setGrade] = useState("");

  const [softwareData, setSoftwareData] = useState([]);
  const [softwareObj, setSoftwareObj] = useState([]);

  const getSoftwareData = () => {
    axios
      .get(getAllSoftwareAPI)
      .then((res) => {
        if (res.data !== null) {
          setSoftwareData(res.data);
        }
      })
      .catch((err) => {
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  useEffect(() => {
    getSoftwareData();
  }, []);

  //   Form Fields

  const [studentData, setStudentData] = useState([]);
  const [studentObj, setStudentObj] = useState(null);

  const [courseData, setCourseData] = useState([]);
  const [courseObj, setCourseObj] = useState(null);

  const [enrollDate, setEnrollDate] = useState("");

  // Table Data Grid
  const [searchTerm, setSearchTerm] = useState("");

  const [pageSize, setPageSize] = useState(5);

  const CustomCellRenderer = (params) => {
    return (
      <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
        {params.value}
      </div>
    );
  };

  const [statusId, setStatusId] = useState("");
  const [completedDate, setCompletedDate] = useState("");

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      headerClassName: "mc-table-head primary",
    },
    {
      field: "student_name",
      headerName: "Student Name",
      headerClassName: "mc-table-head primary",
      renderCell: CustomCellRenderer,
      flex: 1,
    },
    {
      field: "course_name",
      headerName: "Course Name",
      headerClassName: "mc-table-head primary",
      renderCell: CustomCellRenderer,
      flex: 1,
      minWidth: 80,
    },
    {
      field: "fees",
      headerName: "Fees Amount",
      headerClassName: "mc-table-head primary",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "software_names",
      headerName: "Software Covered",
      headerClassName: "mc-table-head primary",
      renderCell: CustomCellRenderer,
      flex: 1,
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "mc-table-head primary",
      renderCell: CustomCellRenderer,
      flex: 1,
      renderCell: (params) => {
        const statusValue = params.value;
        const statusText =
          statusValue === 1 ? "Active" : statusValue === 2 ? "Completed" : "";
        return (
          <Badge
            bg={statusValue === 1 ? "primary" : "success"}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setId(params.row.id);
              setStatusId(params.row.status);
              setGrade(params.row.grade);
              setCompletedDate(params.row.complete_date);
              setCrUpload(true);
              console.log(params.row.certificate + "certificatecertificate");
            }}
          >
            {statusText}
          </Badge>
        );
      },
    },

    {
      field: "complete_date",
      headerName: "Complete Date",
      headerClassName: "mc-table-head primary",

      renderCell: CustomCellRenderer,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        return params.row.complete_date == null
          ? "---"
          : params.row.complete_date;
      },
    },

    {
      field: "grade",
      headerName: "Grade",
      headerClassName: "mc-table-head primary",
      flex: 1,
      sortable: false,
    },
    {
      field: "qrcode",
      headerName: "QR Code",
      headerClassName: "mc-table-head primary",
      renderCell: CustomCellRenderer,
      flex: 1,
      sortable: false,
      renderCell: (params) => {
        if (params.row.status == 2) {
          return (
            <>
              <a
                href={getCertificateDetailsAPI + params.row.id}
                target="_blank"
                // onClick={() => {
                //   navigate()
                //   setQRid(params.row.id);
                //   setQRModal(true);
                // }}
                style={{
                  backgroundColor: "#EFEBFF",
                  borderRadius: "10px",
                  marginLeft: "6px",
                }}
              >
                <MdQrCode2
                  style={{
                    fontSize: "20px",
                    margin: "4px",
                    color: "#5925FF",
                    cursor: "pointer",
                  }}
                />
              </a>
            </>
          );
        }
      },
    },

    // {
    //   field: "action",
    //   headerName: "Action",
    //   headerClassName: "mc-table-head primary",
    //   flex: 1,
    //   sortable: false,
    //   width: 100,
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <span
    //           onClick={() => {
    //             setAlertModal(true);
    //             setId(params.row.id);
    //           }}
    //           style={{
    //             backgroundColor: "#ffdfe4",
    //             borderRadius: "10px",
    //             marginLeft: "6px",
    //           }}
    //         >
    //           <MdDelete
    //             style={{
    //               fontSize: "16px",
    //               margin: "4px",
    //               color: "#f11133",
    //               cursor: "pointer",
    //             }}
    //           />
    //         </span>
    //       </>
    //     );
    //   },
    // },
  ];

  const [tableData, setTabelData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const state = {
    button: 1,
  };

  const saveFun = (e) => {
    e.preventDefault();
    if (state.button === 1) {
      insertFun();
    } else {
      updateFun();
    }
  };

  const updateFun = async () => {
    setLoaderStatus(true);
    let newArr = [];
    await softwareObj.map((item) => {
      newArr.push(item.id);
    });
    const saveObj = {
      id: id,
      student_id: studentObj.id,
      course_id: courseObj.id,
      fees_amount: courseObj.fees,
      enroll_date: moment(enrollDate).format("DD-MM-YYYY"),
      status: 1,
      software_covered: newArr.join(),
    };

    axios
      .put(updateEnrollAPI, saveObj)
      .then((res) => {
        if (res.data === "UPDATED") {
          getEnrollmentData();
          clearFun();
          setLoaderStatus(false);
          toast.success("Enrollment  updated Sucessfully");
        }
      })
      .catch((err) => {
        setLoaderStatus(false);
        toast.error("Opertaion failed, try after sometime");
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  const insertFun = async () => {
    // id, student_id, course_id, fees_amount, enroll_date, status, certificate, createdAt, updatedAt
    let newArr = [];
    await softwareObj.map((item) => {
      newArr.push(item.id);
    });

    const saveObj = {
      student_id: studentObj.id,
      course_id: courseObj.id,
      fees_amount: courseObj.fees,
      enroll_date: moment(enrollDate).format("DD-MM-YYYY"),
      status: 1,
      software_covered: newArr.join(),
    };

    axios
      .post(saveEnrollAPI, saveObj)
      .then((res) => {
        if (res.data === "SAVED") {
          getEnrollmentData();
          setLoaderStatus(false);
          toast.success("Course saved sucessfully");
          clearFun();
        } else if (res.data == "EXISTS") {
          toast.warn("Enrollment already exists.");
        }
      })
      .catch((err) => {
        toast.error("Operation failed, try after sometime");
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  const delFun = async () => {
    axios
      .delete(deleteCourseAPI + id)
      .then((res) => {
        if (res.data === "DELETED") {
          clearFun();
          getCourseData();
          toast.success("Course Deleted Sucessfully");
        }
      })
      .catch((err) => {
        clearFun();
        toast.error("Operation failed, try after sometime");
      });
  };

  const clearFun = () => {
    setId("");
    setCourseObj(null);
    setStudentObj(null);
    setEnrollDate("");
  };

  useEffect(() => {
    setFilterData(
      tableData.filter((item) =>
        item.course_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  const getStudentData = () => {
    axios
      .get(getAllStudentsAPI)
      .then((res) => {
        if (res.data !== null) {
          console.log("====================================");
          console.log(res.data);
          console.log("====================================");
          setStudentData(res.data);
        }
      })
      .catch((err) => {
        setLoaderStatus(false);
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  const getCourseData = () => {
    setLoaderStatus(true);
    axios
      .get(getAllCourseAPI)
      .then((res) => {
        if (res.data !== null) {
          setCourseData(res.data);
          setLoaderStatus(false);
        }
      })
      .catch((err) => {
        setLoaderStatus(false);
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  const getEnrollmentData = () => {
    setLoaderStatus(true);
    axios
      .get(getAllEnrollAPI)
      .then((res) => {
        if (res.data !== null) {
          setFilterData(res.data);
          setTabelData(res.data);
          setLoaderStatus(false);
        }
      })
      .catch((err) => {
        setLoaderStatus(false);
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };
  useEffect(() => {
    getStudentData();
    getCourseData();
    getEnrollmentData();
  }, []);

  const downloadQR = () => {
    const container = document.getElementById("qrcode-container");
    const canvas = container.querySelector("canvas");
    const image = canvas.toDataURL("image/png");

    const anchor = document.createElement("a");
    anchor.href = image;
    anchor.download = "qrcode.png";
    anchor.click();
  };

  const handleUpload = (e) => {
    e.preventDefault();
    setLoaderStatus(true);
    const updateObj = {
      id: id,
      complete_date: statusId == 1 ? null : completedDate,
      status: statusId,
      grade: statusId == 1 ? null : grade,
    };

    axios
      .post(updateEnrollStatusAPI, updateObj)
      .then((response) => {
        if (response.data === "UPDATED") {
          getEnrollmentData();
          setCrUpload(false);
          setLoaderStatus(false);
          setId("");
          setGrade("");
          toast.success("Status sucessfully updated.");
        }
      })

      .catch((error) => {
        setLoaderStatus(false);
        toast.error("Failed to update status.");
        console.error("Error uploading file:", error);
        // Handle error or any other logic here
      });
  };

  return (
    <PageLayout>
      {loaderStatus == true ? <Loader /> : ""}
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title={"Enrollment Master"}>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  {"Home"}
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">{"Enrollment"}</Item>
              <Item className="mc-breadcrumb-item">{"Enrollment Master"}</Item>
            </Breadcrumb>
          </Box>
        </Col>
      </Row>
      <Form onSubmit={saveFun}>
        <Row className="my-2">
          <Col md={6}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>Select Student :</Form.Label>
              <Autocomplete
                fullWidth
                id="tags-standard"
                options={studentData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Student"
                    placeholder="Select Student"
                    className="form-control"
                    size="small"
                    required
                  />
                )}
                onChange={(event, newValue) => {
                  setStudentObj(newValue);
                }}
                value={studentObj}
                getOptionLabel={(option) =>
                  option.name + " | " + option.gender + " | " + option.mobile
                }
              />
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>Select Course :</Form.Label>
              <Autocomplete
                fullWidth
                id="tags-standard"
                options={courseData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select Course"
                    placeholder="Select Course"
                    className="form-control"
                    size="small"
                    required
                  />
                )}
                onChange={(event, newValue) => {
                  setCourseObj(newValue);
                }}
                value={courseObj}
                getOptionLabel={(option) => option.course_name}
              />
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>Enrollment Date :</Form.Label>
              <Form.Control
                type="date"
                required
                value={enrollDate}
                onChange={(e) => setEnrollDate(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col md={9}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>
                Fees : {courseObj !== null ? courseObj.fees + " Rs" : ""}
              </Form.Label>

              <Form.Label style={{ marginLeft: "16px" }}>
                Duration :{" "}
                {courseObj !== null ? courseObj.duration + " days" : ""}
              </Form.Label>
            </Form.Group>
          </Col>
        </Row>

        <Row className="mb-3">
          <Col md={12}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>Software Covered in Course :</Form.Label>
              <Autocomplete
                multiple
                fullWidth
                id="tags-standard"
                options={softwareData}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="Select softwares"
                    placeholder="Select Multiple softwares"
                    className="form-control"
                    size="small"
                    required={softwareObj.length > 0 ? false : true}
                  />
                )}
                onChange={(event, newValue) => {
                  setSoftwareObj(newValue);
                }}
                value={softwareObj}
                getOptionLabel={(option) => option.software_name}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Button
              type="submit"
              className={
                id !== ""
                  ? "btn btn-warning text-dark btn-sm"
                  : "btn btn-primary btn-sm"
              }
              onClick={() => {
                if (id !== "") {
                  state.button = 2;
                } else {
                  state.button = 1;
                }
              }}
            >
              {id !== "" ? "Update" : "Submit"}
            </Button>

            <Button
              type="reset"
              className="btn- btn-danger btn-sm mx-2"
              onClick={clearFun}
            >
              Cancle
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="row my-1">
        <div>
          <input
            //className='form-control form-control-sm'
            type="search"
            style={{
              width: "250px",
              display: "block",
              float: "right",
              marginBottom: "0px",
              border: "1px solid #C2C1C1",
              fontSize: "12px",
              padding: "6px",
              borderRadius: "4px",
            }}
            placeholder="Search Here"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </div>
      </div>

      <Row>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              sx={{
                width: "100%",
                "& .super-app-theme--header": {
                  background: "rgb(43,119,229)",
                  background:
                    "linear-gradient(to top, rgb(43,119,229), #2b77e5)",
                  color: "#fff",
                },
              }}
              autoHeight={true}
              getRowId={(row) => row.id}
              rows={filterData}
              columns={columns}
              disableColumnSelector
              disableSelectionOnClick
              disableColumnFilter
              experimentalFeatures={{ newEditingApi: true }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              pagination
            />
          </div>
        </div>
      </Row>
      <ToastContainer position="top-right" />
      <Modal show={alertModal} onHide={() => setAlertModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to delete this item?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setAlertModal(false);
                clearFun();
              }}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                setAlertModal(false);
                delFun();
              }}
            >
              yes, delete
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>

      <Modal show={qRModal} onHide={() => setQRModal(false)}>
        <Box className="mc-alert-modal pt-5">
          <div id="qrcode-container">
            <QRCode
              value={`${getCertificateDetailsAPI + qrId}`}
              qrStyle="dots"
              ecLevel="H"
              size={300}
              logoImage={qrIcon}
              logoHeight={80}
              logoWidth={80}
            />
          </div>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setQRModal(false);
                setQRid();
              }}
            >
              Close
            </Button>
            <Button
              type="button"
              className="btn btn-success"
              id="download-button"
              onClick={() => {
                downloadQR();
                //setQRModal(false);
              }}
            >
              Download
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>

      <Modal
        show={crUploadModal}
        onHide={() => {
          setCrUpload(false);
          setId("");
        }}
      >
        <Box className="mc-alert-modal">
          <form onSubmit={handleUpload}>
            <div
              style={{ fontWeight: "600", fontSize: "20px", marginTop: "12px" }}
            >
              Course Status Management
            </div>
            <hr />
            <div style={{ textAlign: "left", fontWeight: "600" }}>
              Select Status
            </div>
            <select
              className="form-select"
              defaultValue={statusId}
              onChange={(e) => setStatusId(e.target.value)}
              required
            >
              <option value="" disabled>
                Select Option
              </option>
              <option value="1">Active</option>
              <option value="2">Completed</option>
            </select>

            <div
              style={{ textAlign: "left", fontWeight: "600", marginTop: "8px" }}
            >
              Completed Date
            </div>

            <input
              className="form-control"
              disabled={statusId === 1 ? true : false}
              type="date"
              value={completedDate}
              onChange={(e) => setCompletedDate(e.target.value)}
              required
            />

            <div
              style={{ textAlign: "left", fontWeight: "600", marginTop: "8px" }}
            >
              Grade
            </div>

            <input
              className="form-control"
              disabled={statusId === 1 ? true : false}
              type="text"
              value={grade}
              onChange={(e) => setGrade(e.target.value)}
              required
            />

            <Modal.Footer>
              <Button
                type="reset"
                className="btn btn-secondary"
                onClick={() => {
                  setCrUpload(false);
                  setQRid();
                  setId("");
                }}
              >
                Close
              </Button>
              <Button
                type="submit"
                className="btn btn-primary"
                id="download-button"
              >
                Update
              </Button>
            </Modal.Footer>
          </form>
        </Box>
      </Modal>
    </PageLayout>
  );
};

export default EnrollmentMaster;
