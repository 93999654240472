import React, { useEffect } from "react";
import MenuItem from "./MenuItem";
import { List, Menu, Heading } from "../elements";
//Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userLogout, userLogin } from "../../ReduxStore/Action/LoginAction";
import { useLocation, useNavigate } from "react-router-dom";

export default function MultipleMenu({ data }) {
  const navigate = useNavigate();
  const location = useLocation();
  const authUser = useSelector((state) => state.LoginReducer.list[0]);
  const dispatch = useDispatch();

  useEffect(() => {
    const sessionData = sessionStorage.getItem("bhavika-academy");

    if (sessionData) {
      const parseData = JSON.parse(sessionData);
      dispatch(userLogin(parseData));
      navigate(location.pathname);
    }
    else{
        navigate("/login");
    }
  }, []);
  return (
    <>
      {data?.map((item, index) => (
        <Menu key={index} className="mc-sidebar-menu">
          <Heading as="h5" className="mc-sidebar-menu-title">
            {item.title}
          </Heading>
          <List className="mc-sidebar-menu-list">
            {item.menu.map((item, index) => (
              <MenuItem key={index} item={item} />
            ))}
          </List>
        </Menu>
      ))}
    </>
  );
}
