import React, { useState, useEffect } from "react";
import data from "../../../data/master/analytics.json";
import Modal from "react-bootstrap/Modal";
import PageLayout from "../../../layouts/PageLayout";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Icon,
  Text,
  Item,
  Anchor,
  Heading,
} from "../../../components/elements";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { changePasswordAPI } from "../../../Services/APIURL";
import axios from "axios";
import Loader from "../../../layouts/Loader";

const ChangePassword = () => {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [oldPass, setOldPass] = useState("");
  const [newPass, setNewPass] = useState("");
  const [conPass, setConPass] = useState("");

  const clearFun = () => {
    setOldPass("");
    setNewPass("");
    setConPass("");
  };

  const changePassFun = (e) => {
    e.preventDefault();

    if (newPass !== conPass) {
      toast.warn("New Password and Confirm Password Doesn't Match");
      return false;
    }
    const updateObj = {
      oldPassword: oldPass,
      password: newPass,
    };

    axios
      .post(changePasswordAPI, updateObj)
      .then((res) => {
        if (res.data === "NOTFOUND") {
          toast.warn("You have entered wrong old password");
        } else if (res.data === "UPDATED") {
          clearFun();
          toast.success("Sucessfully password updated");
        }
      })
      .catch((err) => {
        console.log("error", err);
        //toast error message here
        toast.error("Password updation failed.");
      });
  };
  return (
    <PageLayout>
      <ToastContainer position="top-right" />
      {loaderStatus == true ? <Loader /> : ""}
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title={"Change Password"}>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  {"Home"}
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">{"Profile"}</Item>
              <Item className="mc-breadcrumb-item">{"Change Password"}</Item>
            </Breadcrumb>
          </Box>
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={4}>
          <Form onSubmit={changePassFun}>
            <label htmlFor="oldPass">
              <strong>Enter Old Password:</strong>
            </label>
            <input
              className="form-control mb-2"
              id="oldPass"
              placeholder="Enter old password"
              required
              type="password"
              value={oldPass}
              onChange={(e) => setOldPass(e.currentTarget.value)}
            />

            <label htmlFor="newPass" className="mt-2">
              <strong>Enter New Password:</strong>
            </label>
            <input
              className="form-control mb-2"
              id="newPass"
              placeholder="Enter new password"
              required
              type="password"
              value={newPass}
              onChange={(e) => setNewPass(e.currentTarget.value)}
            />

            <label htmlFor="conPass" className="mt-2">
              <strong>Enter Confirm Password:</strong>
            </label>
            <input
              className="form-control mb-2"
              id="conPass"
              placeholder="Enter confirm password"
              required
              type="password"
              value={conPass}
              onChange={(e) => setConPass(e.currentTarget.value)}
            />
            <button type="submit" className="btn btn-primary">
              UPDATE
            </button>
            <button
              type="reset"
              className="btn btn-danger mx-2"
              onClick={clearFun}
            >
              CLEAR
            </button>
          </Form>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default ChangePassword;
