import React from "react";
import DotsMenu from "../DotsMenu";
import { Box, Icon, Text, Heading } from "../elements";

export default function EcommerceCard({ variant, trend, number, title, icon }) {
    return (
        <Box className={`mc-ecommerce-card ${ variant }`}>
            <Icon className="mc-ecommerce-card-trend material-icons">{ trend }</Icon>
            <Box className="mc-ecommerce-card-head">
                <Heading as="h4" className="mc-ecommerce-card-meta">
                    <Text as="span">{ title }</Text>
                    { number }
                </Heading>
                <Icon className="mc-ecommerce-card-icon material-icons">{ icon }</Icon>
            </Box>
            
        </Box>
    );
}