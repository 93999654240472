import React, { useState, useEffect } from "react";
import {
  Box,
  Form,
  Heading,
  Button,
  Anchor,
  Image,
  Text,
} from "../../components/elements";
import IconField from "../../components/fields/IconField";
import Logo from "../../components/Logo";
import data from "../../data/master/login.json";
import { loginCheckApi } from "../../Services/APIURL";
import axios from "axios";

//Redux
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { userLogout, userLogin } from "../../ReduxStore/Action/LoginAction";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import Loader from "../../layouts/Loader";

export default function Login() {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const navigate = useNavigate();
  const authUser = useSelector((state) => state.LoginReducer.list[0]);
  const dispatch = useDispatch();

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const loginFun = (e) => {
    setLoaderStatus(true);
    e.preventDefault();
    const loginObj = {
      username: username,
      password: password,
    };
    axios
      .post(loginCheckApi, loginObj)
      .then((res) => {
        if (res.data === "FAILED") {
          setLoaderStatus(false);
          toast.warn("Invalid login credentials.");
        } else {
          setLoaderStatus(false);
          sessionStorage.setItem("bhavika-academy", JSON.stringify(res.data));
          dispatch(userLogin(res.data));
          navigate("/");
          console.log("====================================");
          console.log(res);
          console.log("====================================");
        }
      })
      .catch((err) => {
        setLoaderStatus(false);
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  useEffect(() => {
    const sessionData = sessionStorage.getItem("bhavika-academy");

    if (sessionData) {
      const parseData = JSON.parse(sessionData);
      dispatch(userLogin(parseData));
      navigate("/");
    }
  }, []);

  console.log("====================================");
  console.log(authUser);
  console.log("====================================");

  return (
    <Box className="mc-auth">
      {loaderStatus == true ? <Loader /> : ""}
      <ToastContainer position="top-right" />
      <Image
        src={data?.pattern.src}
        alt={data?.pattern.alt}
        className="mc-auth-pattern"
      />
      <Box className="mc-auth-group">
        <Logo
          src={data?.logo.src}
          alt={data?.logo.alt}
          href={data?.logo.path}
          className="mc-auth-logo"
        />
        <Heading as="h4" className="mc-auth-title">
          {data?.title}
        </Heading>
        <form className="mc-auth-form" onSubmit={loginFun}>
          <IconField
            icon={"email"}
            type={"text"}
            classes={"w-100 h-sm"}
            placeholder={"Enter your username"}
            // passwordVisible={item.passwordVisible}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
          <IconField
            icon={"lock"}
            type={"password"}
            classes={"w-100 h-sm"}
            placeholder={"Enter your password"}
            passwordVisible={true}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          <button type="submit" className={`mc-auth-btn h-sm`}>
            SIGN IN
          </button>
          <Anchor className="mc-auth-forgot" href={data?.forgot.path}>
            {data?.forgot.text}
          </Anchor>
        </form>
      </Box>
    </Box>
  );
}
