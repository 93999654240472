import React, { useState, useEffect } from "react";
import mainlogo from "../../../bbalogo.png";
import axios from "axios";
import { useParams } from "react-router-dom";
import { getDownloadDataApi } from "../../../Services/APIURL";
import certificateImg from "../../../assets/img/CertificateBg.jpg";
import { QRCode } from "react-qrcode-logo";
import qrIcon from "./barcodeicon.png";
import { getCertificateDetailsAPI } from "../../../Services/MasterServices/enrollmentMasterAPIURL";
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Loader from "../../../layouts/Loader";
import { TroubleshootSharp } from "@mui/icons-material";

const DownloadCertificate = () => {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const { enrollId } = useParams();
  const [data, setData] = useState(null);

  const getData = (enrollId) => {
    setLoaderStatus(TroubleshootSharp);
    axios
      .get(getDownloadDataApi + enrollId)
      .then((res) => {
        if (res.data !== null) {
          setData(res.data);
          setLoaderStatus(false);
          console.log("====================================");
          console.log(JSON.stringify(res.data));
          console.log("====================================");
        }
      })
      .catch((err) => {
        setLoaderStatus(false);
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  useEffect(() => {
    getData(enrollId);
  }, [enrollId]);

  const generatePDF = () => {
    // Create a new jsPDF instance
    const doc = new jsPDF({
      compress: false, // Enable compression
      precision: 5, // Reduce number precision
    });

    // Get the content of the <div> element
    const divContent = document.getElementById("divContent");

    // Convert the <div> content to a canvas element
    html2canvas(divContent, {
      scale: 2, // Scale the canvas for higher resolution
    }).then((canvas) => {
      // Get the data URL of the canvas
      const imgData = canvas.toDataURL("image/jpeg", 0.9); // Reduce image quality

      // Add the image to the PDF
      doc.addImage(imgData, "JPEG", 10, 10, 190, 277);

      // Save the PDF
      doc.save("certificate.pdf");
    });
  };

  if (data) {
    return (
      <div>
        {loaderStatus == true ? <Loader /> : null}
        <div className="certificate-wrapper" id="divContent">
          <div className="certificate-data">
            <div className="studRegNo">
              {data !== null ? data[0].student_master.id ?? "" : ""}
            </div>
            <div className="courseName">
              {data !== null ? data[0].course_master.course_name : ""}
            </div>

            <div className="courseDuration">
              {data !== null ? data[0].course_master.duration : ""}
            </div>

            <div className="courseGrade">
              {data !== null ? data[0].grade : ""}
            </div>

            <div className="softwareCovered">
              {data !== null ? data[1].software_names : ""}
            </div>
            <div className="studName">
              {data !== null ? data[0].student_master.name : ""}
            </div>

            <div className="awardedDate">
              {data !== null ? data[0].complete_date : ""}
            </div>

            <div className="qrCode">
              <QRCode
                value={`${getCertificateDetailsAPI + enrollId}`}
                qrStyle="dots"
                ecLevel="M"
                size={150}
                logoImage={qrIcon}
                logoHeight={24}
                logoWidth={24}
              />
            </div>
          </div>
          <img src={certificateImg} width={"100%"} />
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button className="btn btn-primary" onClick={generatePDF}>
            DOWNLOAD
          </button>
        </div>
      </div>
    );
  } else {
    return (
      <div>
        <h3>No Records Found</h3>
      </div>
    );
  }
};

export default DownloadCertificate;
