import { BaseIp } from "../APIURL";

//Create Course
export const saveCourseAPI = BaseIp + "api/courses/create";

//Update Course
export const updateCourseAPI = BaseIp + "api/courses/update";

//Get Course
export const getAllCourseAPI = BaseIp + "api/courses/getAllCourses";

//Delete Course
export const deleteCourseAPI = BaseIp + "api/courses/delete/";
