// Local Machine
// export const BaseIp = "http://localhost:8080/";

//Web
//export const BaseIp = "https://bhavikaacademy.india24.online/";
export const BaseIp = "https://admin.bhavikaacademy.com/";

// http://localhost:8080/api/login/login-check
export const loginCheckApi = BaseIp + "api/profile/login-check";

//http://localhost:8080/api/enroll/get-download-data/3
export const getDownloadDataApi = BaseIp + "api/enroll/get-download-data/";

export const dashboardStudCount = BaseIp + "api/dashboard/stud-count";
export const dashboardCourseCount = BaseIp + "api/dashboard/course-count";
export const dashboardFees = BaseIp + "api/dashboard/fees";

export const changeEmailAPI = BaseIp + "api/profile/change-email";

export const changePasswordAPI = BaseIp + "api/profile/change-password";
