import { BaseIp } from "../APIURL";

// Get All Software
export const getAllSoftwareAPI = BaseIp + "api/software/getSoftware";

//Create Software
export const saveSoftwareAPI = BaseIp + "api/software/create";

//Update Software
export const updateSoftwareAPI = BaseIp + "api/software/update";

// Get Software By id

export const getSoftwareByIdAPI = BaseIp + "api/software/getSoftware";

//delete software

export const deleteSoftwareAPI = BaseIp + "api/software/deleteSoftware/";
