import { BaseIp } from "../APIURL";

// Get All Software
export const getAllSoftwareAPI = BaseIp + "api/software/getSoftware";

//Create Software
export const saveEnrollAPI = BaseIp + "api/enroll/create";

//Update Software
export const updateEnrollAPI = BaseIp + "api/enroll/update";

//Get all Software
export const getAllEnrollAPI = BaseIp + "api/enroll/getAllEnrollment";

//Get all Enroll by stud id
export const getEnrollmentbyStudIdAPI =
  BaseIp + "api/enroll/getEnrollmentbyStudId/";

// QR Code Path

export const getCertificateDetailsAPI = BaseIp + "#/download-certificate/";

//Certificate upload
export const uploadCertificateAPI = BaseIp + "api/enroll/upload";

// Update Status
export const updateEnrollStatusAPI = BaseIp + "api/enroll/update-status";
