import React, { useState, useEffect } from "react";
import data from "../../../data/master/analytics.json";
import Modal from "react-bootstrap/Modal";
import PageLayout from "../../../layouts/PageLayout";
import { ToastContainer, toast } from "react-toastify";
import Autocomplete from "@mui/material/Autocomplete";
import {
  Box,
  Icon,
  Text,
  Item,
  Anchor,
  Heading,
} from "../../../components/elements";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { useNavigate, useParams } from "react-router-dom";
import { editingStateInitializer } from "@mui/x-data-grid/internals";
import {
  Select,
  MenuItem,
  TextField,
  InputLabel,
  FormControl,
} from "@mui/material";
import {
  saveStudentAPI,
  updateStudentAPI,
  getStudentByIdAPI,
} from "../../../Services/MasterServices/studentMasterAPIURL";
import axios from "axios";
import moment from "moment";
import { red } from "@mui/material/colors";
import { getAllCourseAPI } from "../../../Services/MasterServices/courseMasterAPIURL";
import Loader from "../../../layouts/Loader";
import { getAllSoftwareAPI } from "../../../Services/MasterServices/softwareMasterAPIURL";

const StudentMasterForm = () => {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const { editFlag, id } = useParams();
  const navigate = useNavigate();
  const [alertModal, setAlertModal] = useState(false);
  const [titleLable, setTitleLable] = useState("Student Registration");

  const [selectedDate, setSelectedDate] = useState("");

  const [studId, setStudId] = useState("");
  const [studName, setStudName] = useState("");
  const [fatherName, setFatherName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [dob, setDob] = useState("");

  const [gender, setGender] = useState("");
  const [qualification, setQualification] = useState("");
  const [collegeWork, setCollegeWork] = useState("");
  const [perAdd, setPerAdd] = useState("");
  const [corresAdd, setCorresAdd] = useState("");

  const [courseData, setCourseData] = useState([]);
  const [courseObj, setCourseObj] = useState(null);
  const [enrollDate, setEnrollDate] = useState("");

  const [softwareData, setSoftwareData] = useState([]);
  const [softwareObj, setSoftwareObj] = useState([]);

  const getSoftwareData = () => {
    axios
      .get(getAllSoftwareAPI)
      .then((res) => {
        if (res.data !== null) {
          setSoftwareData(res.data);
        }
      })
      .catch((err) => {
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  useEffect(() => {
    getSoftwareData();
  }, []);

  useEffect(() => {
    if (editFlag == 1) {
      setTitleLable("Student Updation");
      getStudentDetailsById(id);
    } else if (editFlag == 0) {
      setTitleLable("Student Registration");
    }
  }, [editFlag, id]);

  const getCourseData = () => {
    setLoaderStatus(true);
    axios
      .get(getAllCourseAPI)
      .then((res) => {
        if (res.data !== null) {
          setCourseData(res.data);
          setLoaderStatus(false);
        }
      })
      .catch((err) => {
        setLoaderStatus(false);
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  useEffect(() => {
    getCourseData();
  }, []);

  const saveFun = async () => {
    setLoaderStatus(true);
    let newArr = [];
    await softwareObj.map((item) => {
      newArr.push(item.id);
    });

    const saveObj = {
      name: studName,
      father_name: fatherName,
      dob: moment(dob).format("YYYY-MM-DD"),
      email: email,
      gender: gender,
      temp_add: corresAdd,
      per_add: perAdd,
      qualification: qualification,
      college_workplace: collegeWork,
      mobile: mobile,
      course_id: courseObj.id,
      fees_amount: courseObj.fees,
      enroll_date: moment(enrollDate).format("YYYY-MM-DD"),
      status: 1,
      software_covered: newArr.join(),
    };

    axios
      .post(saveStudentAPI, saveObj)
      .then((res) => {
        if (res.data == "SAVED") {
          clearFun();

          toast.success("Registration sucessfully done.");
          setLoaderStatus(false);
          navigate("/student-master/1");
        } else if (res.data == "EXISTS") {
          setLoaderStatus(false);
          toast.warn("Student already registered.");
        }
      })
      .catch((err) => {
        setLoaderStatus(false);
        toast.error("Something went wrong, try after some time.");
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  const clearFun = () => {
    setStudId("");
    setStudName("");
    setFatherName("");
    setEmail("");
    setMobile("");
    setDob("");
    setGender("");
    setQualification("");
    setCollegeWork("");
    setPerAdd("");
    setCorresAdd("");
  };

  const updateStudent = () => {
    setLoaderStatus(true);
    const updateObj = {
      id: studId,
      name: studName,
      father_name: fatherName,
      dob: moment(dob).format("YYYY-MM-DD"),
      email: email,
      gender: gender,
      temp_add: corresAdd,
      per_add: perAdd,
      qualification: qualification,
      college_workplace: collegeWork,
      mobile: mobile,
    };

    axios
      .post(updateStudentAPI, updateObj)
      .then((res) => {
        if (res.data == "UPDATED") {
          setLoaderStatus(false);
          navigate("/student-master/2");
          toast.success("Student updated sucessfully.");
        } else if (res.data == "EXISTS") {
          setLoaderStatus(false);
          toast.warn("Email id or Mobile no is already registered");
        }
      })
      .catch((err) => {
        setLoaderStatus(false);
        toast.error("Something went wrong, try after sometime");
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  const getStudentDetailsById = (sid) => {
    setLoaderStatus(true);
    axios
      .get(`${getStudentByIdAPI}/${sid}`)
      .then((res) => {
        if (res.data !== null) {
          setLoaderStatus(false);
          setStudId(res.data.id);
          setStudName(res.data.name);
          setFatherName(res.data.father_name);
          setEmail(res.data.email);
          setMobile(res.data.mobile);
          setDob(res.data.dob);
          setGender(res.data.gender);
          setQualification(res.data.qualification);
          setCollegeWork(res.data.college_workplace);
          setPerAdd(res.data.per_add);
          setCorresAdd(res.data.temp_add);
        }
      })
      .catch((err) => {
        setLoaderStatus(false);
        console.log(err);
      });
  };

  let state = 1;

  const masterFun = (e) => {
    e.preventDefault();
    if (state === 1) {
      saveFun();
    } else if (state === 2) {
      updateStudent();
    }
  };

  return (
    <PageLayout>
      {loaderStatus == true ? <Loader /> : ""}
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title={titleLable}>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  {"Home"}
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">{"Dashboard"}</Item>
              <Item className="mc-breadcrumb-item">{titleLable}</Item>
            </Breadcrumb>
          </Box>
        </Col>
      </Row>

      <form onSubmit={masterFun}>
        <Row className="my-2">
          <div className="form-group col-md-6">
            <label htmlFor="fullname">
              <strong>Student Full Name</strong>
            </label>

            <TextField
              fullWidth
              id="fullname"
              label="Enter student full name"
              variant="outlined"
              size="small"
              margin="dense"
              required
              value={studName}
              onChange={(e) => setStudName(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="fathername">
              <strong>Father Name</strong>
            </label>
            <TextField
              fullWidth
              id="fathername"
              label="Enter father name"
              variant="outlined"
              size="small"
              margin="dense"
              required
              value={fatherName}
              onChange={(e) => setFatherName(e.target.value)}
            />
          </div>
        </Row>

        <Row className="mb-2">
          <div className="form-group col-md-4">
            <label htmlFor="emailid">
              <strong>Email Id</strong>
            </label>

            <TextField
              fullWidth
              id="emailid"
              type="email"
              label="Enter email id"
              variant="outlined"
              size="small"
              margin="dense"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group col-md-4">
            <label htmlFor="mobileno">
              <strong>Mobile No</strong>
            </label>
            <TextField
              fullWidth
              id="mobileno"
              label="Enter mobile no"
              variant="outlined"
              size="small"
              margin="dense"
              required
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </div>

          <div className="form-group col-md-4">
            <label htmlFor="dob">
              <strong>Date of Birth:</strong>
            </label>
            <TextField
              fullWidth
              id="DOB"
              label="DOB"
              type="date"
              value={dob}
              onChange={(event) => setDob(event.target.value)}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              margin="dense"
              required
            />
          </div>
        </Row>

        <Row className="mb-2">
          <div className="form-group col-md-4">
            <label htmlFor="gender">
              <strong>Gender</strong>
            </label>

            <FormControl fullWidth margin="dense" required>
              <InputLabel id="gender">Gender</InputLabel>
              <Select
                labelId="gender"
                id="gender"
                value={gender}
                label="Gender"
                onChange={(e) => setGender(e.target.value)}
                fullWidth
                size="small"
              >
                <MenuItem value={"Male"} selected>
                  Male
                </MenuItem>
                <MenuItem value={"Female"}>Female</MenuItem>
              </Select>
            </FormControl>
          </div>
          <div className="form-group col-md-8">
            <label htmlFor="qualification">
              <strong>Qualification</strong>
            </label>
            <TextField
              fullWidth
              id="qualification"
              label="Enter Qualification"
              variant="outlined"
              size="small"
              margin="dense"
              required
              value={qualification}
              onChange={(e) => setQualification(e.target.value)}
            />
          </div>
        </Row>

        <Row className="mb-2">
          <div className="form-group col-md-6">
            <label htmlFor="padd">
              <strong>Parmenent Address</strong>
            </label>

            <TextField
              id="padd"
              label="Parmenent Address"
              multiline
              fullWidth
              rows={4}
              variant="outlined"
              size="small"
              margin="dense"
              required
              value={perAdd}
              onChange={(e) => setPerAdd(e.target.value)}
            />
          </div>
          <div className="form-group col-md-6">
            <label htmlFor="cadd">
              <strong>Correspondance Address</strong>
            </label>
            <TextField
              id="cadd"
              label="Correspondance Address"
              fullWidth
              multiline
              rows={4}
              variant="outlined"
              size="small"
              margin="dense"
              value={corresAdd}
              onChange={(e) => setCorresAdd(e.target.value)}
            />
          </div>
        </Row>

        <Row className="mb-2">
          <div className="form-group col-md-12">
            <label htmlFor="clgwork">
              <strong>College / Workplace</strong>
            </label>

            <TextField
              fullWidth
              id="clgwork"
              label="Enter college / workplace"
              variant="outlined"
              size="small"
              margin="dense"
              required
              value={collegeWork}
              onChange={(e) => setCollegeWork(e.target.value)}
            />
          </div>
        </Row>

        {editFlag == 0 ? (
          <>
            <Row>
              <Col md={6}>
                <Form.Group className="mb-1" controlId="formBasicEmail">
                  <Form.Label>Select Course :</Form.Label>
                  <Autocomplete
                    fullWidth
                    id="tags-standard"
                    options={courseData}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select Course"
                        placeholder="Select Course"
                        className="form-control"
                        size="small"
                        required
                      />
                    )}
                    onChange={(event, newValue) => {
                      setCourseObj(newValue);
                    }}
                    value={courseObj}
                    getOptionLabel={(option) => option.course_name}
                  />
                </Form.Group>
              </Col>
              <Col md={3}>
                <Form.Group className="mb-1" controlId="formBasicEmail">
                  <Form.Label>Enrollment Date :</Form.Label>
                  <Form.Control
                    type="date"
                    required
                    value={enrollDate}
                    onChange={(e) => setEnrollDate(e.target.value)}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col md={12}>
                <Form.Group className="mb-1" controlId="formBasicEmail">
                  <Form.Label>Software Covered in Course :</Form.Label>
                  <Autocomplete
                    multiple
                    fullWidth
                    id="tags-standard"
                    options={softwareData}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label="Select softwares"
                        placeholder="Select Multiple softwares"
                        className="form-control"
                        size="small"
                        required={softwareObj.length > 0 ? false : true}
                      />
                    )}
                    onChange={(event, newValue) => {
                      setSoftwareObj(newValue);
                    }}
                    value={softwareObj}
                    getOptionLabel={(option) => option.software_name}
                  />
                </Form.Group>
              </Col>
            </Row>
          </>
        ) : null}

        <button
          type="submit"
          className={studId == "" ? "btn btn-primary mt-3" : "d-none"}
          onClick={() => (state = 1)}
        >
          Submit
        </button>

        <button
          type="submit"
          className={studId == "" ? "d-none" : "btn btn-warning mt-3"}
          //className="btn btn-warning mt-3"
          onClick={() => (state = 2)}
        >
          Update
        </button>

        <button
          type="reset"
          className="btn btn-danger mt-3 mx-2"
          onClick={() => navigate("/student-master/0")}
        >
          Cancle
        </button>
      </form>

      <ToastContainer position="top-right" />
    </PageLayout>
  );
};

export default StudentMasterForm;
