import React, { useState, useEffect } from "react";
import data from "../../../data/master/analytics.json";
import Modal from "react-bootstrap/Modal";
import PageLayout from "../../../layouts/PageLayout";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Icon,
  Text,
  Item,
  Anchor,
  Heading,
} from "../../../components/elements";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
} from "@mui/x-data-grid";
import { MdEdit, MdDelete } from "react-icons/md";
import axios from "axios";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { getAllSoftwareAPI } from "../../../Services/MasterServices/softwareMasterAPIURL";
import {
  getAllCourseAPI,
  saveCourseAPI,
  deleteCourseAPI,
  updateCourseAPI,
} from "../../../Services/MasterServices/courseMasterAPIURL";
import Loader from "../../../layouts/Loader";

const CourseMaster = () => {
  const [loaderOption, setLoaderOption] = useState(false);
  const [alertModal, setAlertModal] = useState(false);
  const [ind, setInd] = useState("");
  const [id, setId] = useState("");
  const [name, setName] = useState("");

  //   Form Fields
  const [courseName, setCourseName] = useState("");
  const [fees, setFees] = useState("");
  const [duration, setDuration] = useState("");
  const [softwareData, setSoftwareData] = useState([]);
  const [softwareObj, setSoftwareObj] = useState([]);

  const [searchTerm, setSearchTerm] = useState("");

  const [pageSize, setPageSize] = useState(5);

  const CustomCellRenderer = (params) => {
    return (
      <div style={{ whiteSpace: "pre-wrap", overflowWrap: "break-word" }}>
        {params.value}
      </div>
    );
  };

  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      headerClassName: "mc-table-head primary",
    },
    {
      field: "course_name",
      headerName: "Course Name",
      headerClassName: "mc-table-head primary",
      flex: 1,
    },
    {
      field: "fees",
      headerName: "Fees",
      headerClassName: "mc-table-head primary",
      flex: 1,
      minWidth: 80,
    },
    {
      field: "duration",
      headerName: "Course Duration",
      headerClassName: "mc-table-head primary",
      flex: 1,
      minWidth: 80,
    },
    
    // { field: 'name', headerName: 'Name', width: 200, renderCell: CustomCellRenderer },

    {
      field: "action",
      headerName: "Action",
      headerClassName: "mc-table-head primary",
      flex: 1,
      sortable: false,
      width: 100,
      renderCell: (params) => {
        const onClick = (params) => {
          setCourseName(params.row.course_name);
          setId(params.row.id);
          setDuration(params.row.duration);
          setFees(params.row.fees);

          // const data2Array = params.row.software_covered.split(",");
          // console.log(data2Array);
          // const filteredData = softwareData.filter((item) =>
          //   data2Array.includes(item.id.toString())
          // );
          // setSoftwareObj(filteredData);
        };

        return (
          <>
            <span
              onClick={() => {
                onClick(params);
              }}
              style={{ backgroundColor: "#ddfbe9", borderRadius: "10px" }}
            >
              <MdEdit
                style={{
                  fontSize: "16px",
                  margin: "4px",
                  color: "#1a9f53",
                  cursor: "pointer",
                }}
              />
            </span>

            <span
              onClick={() => {
                setAlertModal(true);
                setId(params.row.id);
              }}
              style={{
                backgroundColor: "#ffdfe4",
                borderRadius: "10px",
                marginLeft: "6px",
              }}
            >
              <MdDelete
                style={{
                  fontSize: "16px",
                  margin: "4px",
                  color: "#f11133",
                  cursor: "pointer",
                }}
              />
            </span>
          </>
        );
      },
    },
  ];

  const [tableData, setTabelData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  const state = {
    button: 1,
  };

  const saveFun = (e) => {
    e.preventDefault();
    if (state.button === 1) {
      insertFun();
    } else {
      updateFun();
    }
  };

  const updateFun = async () => {
    setLoaderOption(true);
    //Typeid, TypeName, Status, Created_by_id, dt_created_on, Updated_by_id, last_updated_on, Companyid
    

    const saveObj = {
      id: id,
      course_name: courseName,
      fees: fees,
      duration: duration,
      
    };

    axios
      .put(updateCourseAPI, saveObj)
      .then((res) => {
        if (res.data === "UPDATED") {
          getCourseData();
          clearFun();
          setLoaderOption(false);
          toast.success("Course updated Sucessfully");
        }
      })
      .catch((err) => {
        setLoaderOption(false);
        toast.error("Opertaion failed, try after sometime");
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  const insertFun = async () => {
    setLoaderOption(true);
    //Typeid, TypeName, Status, Created_by_id, dt_created_on, Updated_by_id, last_updated_on, Companyid

    const saveObj = {
      course_name: courseName,
      fees: fees,
      duration: duration,
    };

    axios
      .post(saveCourseAPI, saveObj)
      .then((res) => {
        if (res.data === "SAVED") {
          getCourseData();
          setLoaderOption(false);
          toast.success("Course saved sucessfully");
          clearFun();
        }
      })
      .catch((err) => {
        setLoaderOption(false);
        toast.success("Operation failed, try after sometime");
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  const delFun = async () => {
    setLoaderOption(true);
    axios
      .delete(deleteCourseAPI + id)
      .then((res) => {
        if (res.data === "DELETED") {
          clearFun();
          getCourseData();
          setLoaderOption(false);
          toast.success("Course Deleted Sucessfully");
        }
      })
      .catch((err) => {
        clearFun();
        setLoaderOption(false);
        toast.error("Operation failed, try after sometime");
      });
  };

  const clearFun = () => {
    setId("");
    setCourseName("");
    setFees("");
    setDuration("");
    setSoftwareObj([]);
  };

  useEffect(() => {
    setFilterData(
      tableData.filter((item) =>
        item.course_name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  const getSoftwareData = () => {
    axios
      .get(getAllSoftwareAPI)
      .then((res) => {
        if (res.data !== null) {
          setSoftwareData(res.data);
        }
      })
      .catch((err) => {
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };

  const getCourseData = () => {
    axios
      .get(getAllCourseAPI)
      .then((res) => {
        if (res.data !== null) {
          setTabelData(res.data);
          setFilterData(res.data);
        }
      })
      .catch((err) => {
        console.log("====================================");
        console.log(err);
        console.log("====================================");
      });
  };
  useEffect(() => {
    getSoftwareData();
    getCourseData();
  }, []);

  return (
    <PageLayout>
      {loaderOption === true ? <Loader /> : null}
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title={"Course Master"}>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  {"Home"}
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">{"Dashboard"}</Item>
              <Item className="mc-breadcrumb-item">{"Course Master"}</Item>
            </Breadcrumb>
          </Box>
        </Col>
      </Row>
      <Form onSubmit={saveFun}>
        <Row className="my-2">
          <Col md={6}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>Course Name :</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter Course Name..."
                required
                value={courseName}
                onChange={(e) => setCourseName(e.target.value)}
              />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>Fees :</Form.Label>
              <Form.Control
                type="number"
                placeholder="Enter fees..."
                required
                value={fees}
                onChange={(e) => setFees(e.target.value)}
              />
            </Form.Group>
          </Col>

          <Col md={3}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label>Course Duration :</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter course duration..."
                required
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row>
          <Col md={12}>
            <Button
              type="submit"
              className={
                id !== ""
                  ? "btn btn-warning text-dark btn-sm"
                  : "btn btn-primary btn-sm"
              }
              onClick={() => {
                if (id !== "") {
                  state.button = 2;
                } else {
                  state.button = 1;
                }
              }}
            >
              {id !== "" ? "Update" : "Submit"}
            </Button>

            <Button
              type="reset"
              className="btn- btn-danger btn-sm mx-2"
              onClick={clearFun}
            >
              Cancle
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="row my-1">
        <div>
          <input
            //className='form-control form-control-sm'
            type="search"
            style={{
              width: "250px",
              display: "block",
              float: "right",
              marginBottom: "0px",
              border: "1px solid #C2C1C1",
              fontSize: "12px",
              padding: "6px",
              borderRadius: "4px",
            }}
            placeholder="Search Here"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </div>
      </div>

      <Row>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              sx={{
                width: "100%",
                "& .super-app-theme--header": {
                  background: "rgb(43,119,229)",
                  background:
                    "linear-gradient(to top, rgb(43,119,229), #2b77e5)",
                  color: "#fff",
                },
              }}
              autoHeight={true}
              getRowId={(row) => row.id}
              rows={filterData}
              columns={columns}
              disableColumnSelector
              disableSelectionOnClick
              disableColumnFilter
              experimentalFeatures={{ newEditingApi: true }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              pagination
            />
          </div>
        </div>
      </Row>
      <ToastContainer position="top-right" />
      <Modal show={alertModal} onHide={() => setAlertModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to delete this item?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setAlertModal(false);
                clearFun();
              }}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              onClick={() => {
                setAlertModal(false);
                delFun();
              }}
            >
              yes, delete
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </PageLayout>
  );
};

export default CourseMaster;
