import { BaseIp } from "../APIURL";

// Get All STudents
export const getAllStudentsAPI = BaseIp + "api/student/getStudents";

//Create Student
export const saveStudentAPI = BaseIp + "api/student/create";


//Update Student
export const updateStudentAPI = BaseIp + "api/student/update";

// Get Student By id

export const getStudentByIdAPI=BaseIp+"api/student/getStudent"