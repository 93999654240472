import React, { useState, useEffect } from "react";
import data from "../../../data/master/analytics.json";
import Modal from "react-bootstrap/Modal";
import PageLayout from "../../../layouts/PageLayout";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Icon,
  Text,
  Item,
  Anchor,
  Heading,
} from "../../../components/elements";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import {
  DataGrid,
  GridToolbarQuickFilter,
  GridLinkOperator,
} from "@mui/x-data-grid";
import { MdEdit, MdDelete } from "react-icons/md";
import axios from "axios";
import { getAllStudentsAPI } from "../../../Services/MasterServices/studentMasterAPIURL";
import { useParams, useNavigate } from "react-router-dom";

const StudentMasterTable = () => {
  const { editFlag } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (editFlag == 1) {
      navigate("/student-master/0");
      toast.success("Student Sucessfully Registered.");
    } else if (editFlag == 2) {
      navigate("/student-master/0");
      toast.success("Student Sucessfully Updated.");
    }
  }, [editFlag]);

  const [alertModal, setAlertModal] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const [pageSize, setPageSize] = useState(5);
  const columns = [
    {
      field: "id",
      headerName: "ID",
      width: 80,
      headerClassName: "mc-table-head primary",
    },
    {
      field: "name",
      headerName: "Student Name",
      headerClassName: "mc-table-head primary",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "gender",
      headerName: "Gender",
      headerClassName: "mc-table-head primary",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "mobile",
      headerName: "Mobile",
      headerClassName: "mc-table-head primary",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "email",
      headerName: "Email",
      headerClassName: "mc-table-head primary",
      flex: 1,
      minWidth: 120,
    },
    {
      field: "action",
      headerName: "Action",
      headerClassName: "mc-table-head primary",
      flex: 1,
      sortable: false,
      width: 50,
      renderCell: (params) => {
        const onClick = (id) => {
            console.log("=-=-=-=-=-=-"+params.id);
          navigate(`/student-reg/1/${params.id}`);
        };

        return (
          <>
            <span
              onClick={() => {
                onClick(params.row.Typeid, params.row.TypeName);
              }}
              style={{ backgroundColor: "#ddfbe9", borderRadius: "10px" }}
            >
              <MdEdit
                style={{
                  fontSize: "16px",
                  margin: "4px",
                  color: "#1a9f53",
                  cursor: "pointer",
                }}
              />
            </span>

            {/* <span
              onClick={() => {
                setAlertModal(true);
                //setId(params.row.Typeid);
              }}
              style={{
                backgroundColor: "#ffdfe4",
                borderRadius: "10px",
                marginLeft: "6px",
              }}
            >
              <MdDelete
                style={{
                  fontSize: "16px",
                  margin: "4px",
                  color: "#f11133",
                  cursor: "pointer",
                }}
              />
            </span> */}
          </>
        );
      },
    },
  ];

  const [tableData, setTabelData] = useState([]);
  const [filterData, setFilterData] = useState([]);

  useEffect(() => {
    setFilterData(
      tableData.filter((item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase())
      )
    );
  }, [searchTerm]);

  const getStudentData = () => {
    axios.get(getAllStudentsAPI).then((res) => {
      setTabelData(res.data);
      setFilterData(res.data);
    });
  };
  useEffect(() => {
    getStudentData();
  }, []);

  return (
    <PageLayout>
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title={"Student Master"}>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  {"Home"}
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">{"Dashboard"}</Item>
              <Item className="mc-breadcrumb-item">{"Student Master"}</Item>
            </Breadcrumb>
          </Box>
        </Col>
      </Row>

      <div className="row my-1">
        <div>
          <input
            //className='form-control form-control-sm'
            type="button"
            style={{
              width: "250px",
              display: "block",
              float: "left",
              marginBottom: "0px",
              border: "1px solid #C2C1C1",
              fontSize: "14px",
              padding: "6px",
              borderRadius: "4px",
              backgroundColor: "#5E6BF2",
              color: "#fff",
            }}
            value={"New Student Register"}
            onClick={() => navigate("/student-reg/0/0")}
          />
          <input
            //className='form-control form-control-sm'
            type="search"
            style={{
              width: "250px",
              display: "block",
              float: "right",
              border: "1px solid #C2C1C1",
              fontSize: "12px",
              padding: "6px",
              borderRadius: "4px",
            }}
            placeholder="Search Here"
            value={searchTerm}
            onChange={(event) => {
              setSearchTerm(event.target.value);
            }}
          />
        </div>
      </div>

      <Row>
        <div style={{ display: "flex", height: "100%" }}>
          <div style={{ flexGrow: 1 }}>
            <DataGrid
              sx={{
                width: "100%",
                "& .super-app-theme--header": {
                  background: "rgb(43,119,229)",
                  background:
                    "linear-gradient(to top, rgb(43,119,229), #2b77e5)",
                  color: "#fff",
                },
              }}
              autoHeight={true}
              getRowId={(row) => row.id}
              rows={filterData}
              columns={columns}
              disableColumnSelector
              disableSelectionOnClick
              disableColumnFilter
              experimentalFeatures={{ newEditingApi: true }}
              pageSize={pageSize}
              onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
              rowsPerPageOptions={[5, 10, 25, 50, 100]}
              pagination
            />
          </div>
        </div>
      </Row>
      <ToastContainer position="top-right" />
      <Modal show={alertModal} onHide={() => setAlertModal(false)}>
        <Box className="mc-alert-modal">
          <Icon type="new_releases" />
          <Heading as="h3">are your sure!</Heading>
          <Text as="p">Want to delete this item?</Text>
          <Modal.Footer>
            <Button
              type="button"
              className="btn btn-secondary"
              //   onClick={() => {
              //     setAlertModal(false);
              //     clearFun();
              //   }}
            >
              nop, close
            </Button>
            <Button
              type="button"
              className="btn btn-danger"
              //   onClick={() => {
              //     setAlertModal(false);
              //     delFun();
              //   }}
            >
              yes, delete
            </Button>
          </Modal.Footer>
        </Box>
      </Modal>
    </PageLayout>
  );
};

export default StudentMasterTable;
