import React, { useState, useEffect } from "react";
import data from "../../../data/master/analytics.json";
import Modal from "react-bootstrap/Modal";
import PageLayout from "../../../layouts/PageLayout";
import { ToastContainer, toast } from "react-toastify";
import {
  Box,
  Icon,
  Text,
  Item,
  Anchor,
  Heading,
} from "../../../components/elements";
import { Row, Col, Form, Button } from "react-bootstrap";
import { Breadcrumb } from "../../../components";
import { changeEmailAPI } from "../../../Services/APIURL";
import axios from "axios";
import Loader from "../../../layouts/Loader";

const ChangeEmail = () => {
  const [loaderStatus, setLoaderStatus] = useState(false);

  const [email, setEmail] = useState("");

  const updateEmailFun=(e)=>{
    e.preventDefault();
    setLoaderStatus(true);
    const updateObj={
        username:email
    }
    axios.post(changeEmailAPI, updateObj)
    .then((res)=>{
        if(res.data ==="UPDATED"){
            setLoaderStatus(false);
            setEmail("")
            toast.success("Email updated sucessfully.")
        }
    })
    .catch((err)=>{
        setLoaderStatus(false);
        toast.error("Email updation failed.")
        console.log('====================================');
        console.log(err);
        console.log('====================================');
    })
  }
  return (
    <PageLayout>
    <ToastContainer position="top-right" />
      {loaderStatus == true ? <Loader /> : ""}
      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title={"Change Email"}>
              <Item className="mc-breadcrumb-item">
                <Anchor className="mc-breadcrumb-link" href={"/"}>
                  {"Home"}
                </Anchor>
              </Item>
              <Item className="mc-breadcrumb-item">{"Profile"}</Item>
              <Item className="mc-breadcrumb-item">{"Change Email"}</Item>
            </Breadcrumb>
          </Box>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <Form onSubmit={updateEmailFun}>
            <label htmlFor="email">
              <strong>Enter New Email Id:</strong>
            </label>
            <input
              className="form-control mb-2"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              type="email"
            />
            <button type="submit" className="btn btn-primary">
              UPDATE
            </button>
            <button type="reset" className="btn btn-danger mx-2" onClick={()=> setEmail("")}>
              CLEAR
            </button>
          </Form>
        </Col>
      </Row>
    </PageLayout>
  );
};

export default ChangeEmail;
