import React from "react";
import { Box, Anchor } from "../elements";
import { useNavigate } from "react-router-dom";

export default function Logout({ data }) {
const navigate=useNavigate()

    const logoutFun = () => {
        sessionStorage.removeItem("bhavika-academy")
        navigate("/login")
    }
    return (
        <Box className="mc-sidebar-logout text-center">
            <button className = "mc-btn primary" onClick={logoutFun}>Logout</button>
        </Box>
    )
}