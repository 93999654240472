import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { Breadcrumb } from "../../components";
import PageLayout from "../../layouts/PageLayout";
import data from "../../data/master/ecommerce.json";
import { Box, Item, Anchor } from "../../components/elements";
import {
  EcommerceCard,
  SalesCard,
  ProductsCard,
  RevenueCard,
  ClientsCard,
  ActivityCard,
  OrdersCard,
} from "../../components/cards";
import {
  dashboardStudCount,
  dashboardCourseCount,
  dashboardFees,
} from "../../Services/APIURL";
import axios from "axios";
import Loader from "../../layouts/Loader";

export default function Ecommerce() {
  const [loaderStatus, setLoaderStatus] = useState(false);
  const [studeCount, setStudCount] = useState("");
  const [courseCount, setCourseCount] = useState("");
  const [feesCount, setFeesCount] = useState("");

  const getStudentCount = async () => {
    setLoaderStatus(true);
    axios
      .get(dashboardStudCount)
      .then((res) => {
        setStudCount(res.data.studcount);
        setLoaderStatus(false);
      })
      .catch((err) => {
        setLoaderStatus(false);
        console.log("====================================");
        console.log("Error " + err);
        console.log("====================================");
      });
  };

  const getCourseCount = async () => {
    setLoaderStatus(true);
    axios
      .get(dashboardCourseCount)
      .then((res) => {
        setCourseCount(res.data.coursecount);
        setLoaderStatus(false);
      })
      .catch((err) => {
        setLoaderStatus(false);
        console.log("====================================");
        console.log("Error " + err);
        console.log("====================================");
      });
  };
  const getFeesCount = async () => {
    setLoaderStatus(true);
    axios
      .get(dashboardFees)
      .then((res) => {
        setFeesCount(res.data.paid_amount);
        setLoaderStatus(false);
      })
      .catch((err) => {
        setLoaderStatus(false);
        console.log("====================================");
        console.log("Error " + err);
        console.log("====================================");
      });
  };

  useEffect(() => {
    getStudentCount();
    getCourseCount();
    getFeesCount();
  }, []);

  return (
    <PageLayout>
      {loaderStatus == true ? <Loader /> : ""}

      <Row>
        <Col xl={12}>
          <Box className="mc-card">
            <Breadcrumb title={data?.pageTitle}>
              {data?.breadcrumb?.map((item, index) => (
                <Item key={index} className="mc-breadcrumb-item">
                  {item.path ? (
                    <Anchor className="mc-breadcrumb-link" href={item.path}>
                      {item.text}
                    </Anchor>
                  ) : (
                    item.text
                  )}
                </Item>
              ))}
            </Breadcrumb>
          </Box>
        </Col>
        <Col xs={12} xl={12} md={12}>
          <Row xs={1} sm={3}>
            {/* {data?.heros?.map((item, index) => (
              <Col key={index}>
                <EcommerceCard
                  icon={item.icon}
                  trend={item.trend}
                  title={item.title}
                  number={item.number}
                  variant={item.variant}
                  percent={item.percent}
                  compare={item.compare}
                  dotsMenu={item.dotsMenu}
                />
              </Col>
            ))} */}

            <Col>
              <EcommerceCard
                icon={"account_circle"}
                trend={"trending_up"}
                title={"Total Students"}
                number={studeCount}
                variant={"green"}
              />
            </Col>
            <Col>
              <EcommerceCard
                icon={"library_books"}
                trend={"trending_up"}
                title={"Total Courses"}
                number={courseCount}
                variant={"purple"}
              />
            </Col>

            <Col>
              <EcommerceCard
                icon={"account_balance_wallet"}
                trend={"trending_up"}
                title={"Total Fees Collection"}
                number={feesCount}
                variant={"yellow"}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </PageLayout>
  );
}
